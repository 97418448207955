import { Box, HStack, Icon } from "@chakra-ui/react";
import { AiOutlineLinkedin } from "react-icons/ai";
import { TfiGithub, TfiSave } from "react-icons/tfi";

const Footer = () => {
  const linkedInUrl = "https://www.linkedin.com/in/torres-adrian/";
  const githubUrl = "https://github.com/AdrTowers";
  const downloadCv = () => {
    fetch("TorresResume.pdf").then((res) => {
      res.blob().then((blob) => {
        const fileUrl = window.URL.createObjectURL(blob);
        let ele = document.createElement("a");
        ele.href = fileUrl;
        ele.download = "TorresResume.pdf";
        ele.click();
      });
    });
  };

  return (
    <Box flexWrap="nowrap" display="flex" bg="#4E4F50" padding={4} w="100%">
      <Box w="100%">
        <HStack justifyContent="flex-end" spacing={4}>
          <Icon
            as={AiOutlineLinkedin}
            boxSize={6}
            onClick={() => window.open(linkedInUrl, "_blank")}
          />
          <Icon
            as={TfiGithub}
            boxSize={6}
            onClick={() => window.open(githubUrl, "_blank")}
          />
          <Icon as={TfiSave} boxSize={5} onClick={downloadCv} />
        </HStack>
      </Box>
    </Box>
  );
};

export default Footer;
