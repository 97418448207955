import {
  Box,
  Text,
  Image,
  Heading,
  Flex,
  SimpleGrid,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { AiOutlineLinkedin } from "react-icons/ai";
import { TfiGithub, TfiSave } from "react-icons/tfi";

function Hero() {
  const linkedInUrl = "https://www.linkedin.com/in/torres-adrian/";
  const githubUrl = "https://github.com/AdrTowers";
  const downloadCv = () => {
    fetch("TorresResume.pdf").then((res) => {
      res.blob().then((blob) => {
        const fileUrl = window.URL.createObjectURL(blob);
        let ele = document.createElement("a");
        ele.href = fileUrl;
        ele.download = "TorresResume.pdf";
        ele.click();
      });
    });
  };

  return (
    <Box
      id="hero"
      display="flex"
      bg="#647C90"
      color="#E2DED0"
      flexWrap="wrap-reverse"
    >
      <Box flex={1} margin={6} display="flex" justifyContent="center">
        <Flex align="center" justify="center" minW="200px">
          <SimpleGrid columns={1} spacing="10px" gap={4} templateRows="2">
            <Box rowSpan={2} colSpan={1}>
              <Heading fontSize={62} letterSpacing="1.5px">
                Hello! <span className="wave_emoji">&#128400;&#65039;</span>
              </Heading>
              <Heading fontSize={52} letterSpacing="1.5px">
                I'm Adrian
              </Heading>
              <Text fontSize={38}>
                <br />
                <Text letterSpacing="1.5px">
                  Software Engineer &#128104;&#127997;&#8205;&#128187;
                </Text>
                <Text letterSpacing="1.5px">Veteran &#9875;</Text>
                <Text letterSpacing="1.5px">Tech Enthusiast &#128187;</Text>
                <br />
              </Text>
              <HStack spacing={4}>
                <hr width="100%" />
                <Icon
                  as={AiOutlineLinkedin}
                  boxSize={6}
                  onClick={() => window.open(linkedInUrl, "_blank")}
                />
                <Icon
                  as={TfiGithub}
                  boxSize={6}
                  onClick={() => window.open(githubUrl, "_blank")}
                />
                <Icon as={TfiSave} boxSize={5} onClick={downloadCv} />
                <hr width="100%" />
              </HStack>
            </Box>
          </SimpleGrid>
        </Flex>
      </Box>
      <Flex align="center" justify="center" gridAutoRows="auto">
        <Image
          src="profphoto.jpg"
          rounded="full"
          maxWidth="400px"
          height="75%"
        />
      </Flex>
    </Box>
  );
}

export default Hero;
