import { Box, Text, Card, CardBody, VStack } from "@chakra-ui/react";

const About = () => {
  return (
    <Box id="about" bg="#647C90" color="#DEE6E0" paddingBottom={6}>
      <VStack>
        <Box
          alignSelf="start"
          width="50%"
          display="inline-block"
          marginLeft={4}
        >
          <Text fontSize="4xl">How's it going?</Text>
        </Box>
        <Box padding={4} justifyContent="center" maxW="800px">
          <Card bg="#C0C0C0" color="black" variant="elevated" size="lg">
            <CardBody>
              <Text fontSize={22}>
                I'm a Seattle based Software Engineer with 2+ years of
                experience. I previously worked at a startup, where I built a
                MVP, APIs, and communicated with stakeholders. Most recently I
                worked at Amazon, where I had the opportunity to work on
                features millions of customers use daily. My military experience
                allowed me to leverage my soft skills and adaptability in order
                to communicate effectively and deliver clean code in a fast
                paced environment.
                <br />
                Outside of work I like to spend time with my family, help
                veterans get into tech, and explore WA! &#9968;&#65039;
              </Text>
            </CardBody>
          </Card>
        </Box>
      </VStack>
    </Box>
  );
};

export default About;
