import { techLanguages } from "../techLanguages.js";
import {
  Box,
  Text,
  Wrap,
  WrapItem,
  Center,
  VStack,
  Tooltip,
} from "@chakra-ui/react";

const Skills = () => {
  return (
    <Box
      id="skills"
      display="flex"
      bg="#647C90"
      color="#DEE6E0"
      paddingBottom={6}
    >
      <VStack>
        <Box flex={1} marginLeft={4} marginTop={6} alignSelf="start">
          <Text fontSize="4xl">Skills</Text>
          <Text color="#C0C0C0" paddingLeft={8} flexWrap="inherit">
            Here's a list of the technologies I've used.
          </Text>
        </Box>
        <Wrap justify="center" paddingTop={6}>
          {techLanguages.map((tech) => (
            <WrapItem key={tech.id}>
              <Center w="150px" h="80px" fontSize="50px">
                <Tooltip label={tech.name}>{tech.icon}</Tooltip>
              </Center>
            </WrapItem>
          ))}
        </Wrap>
      </VStack>
    </Box>
  );
};

export default Skills;
