export const techLanguages = [
  {
    id: 0,
    name: "ReactJS",
    icon: <i className="devicon-react-plain colored"></i>,
  },
  {
    id: 1,
    name: "Java",
    icon: <i className="devicon-java-plain"></i>,
  },
  {
    id: 2,
    name: "JavaScript",
    icon: <i className="devicon-javascript-plain colored"></i>,
  },
  {
    id: 3,
    name: "TypeScript",
    icon: <i className="devicon-typescript-plain colored"></i>,
  },
  {
    id: 4,
    name: "Redux",
    icon: <i className="devicon-redux-original colored"></i>,
  },
  {
    id: 5,
    name: "C#",
    icon: <i className="devicon-csharp-plain colored"></i>,
  },
  {
    id: 6,
    name: "Jquery",
    icon: <i className="devicon-jquery-plain colored"></i>,
  },
  {
    id: 7,
    name: "HTML5",
    icon: <i className="devicon-html5-plain colored"></i>,
  },
  {
    id: 8,
    name: "CSS3",
    icon: <i className="devicon-css3-plain colored"></i>,
  },
  {
    id: 9,
    name: "Python",
    icon: <i className="devicon-python-plain colored"></i>,
  },
  {
    id: 10,
    name: "Git",
    icon: <i className="devicon-git-plain colored"></i>,
  },
  {
    id: 11,
    name: "Node.js",
    icon: <i className="devicon-nodejs-plain colored"></i>,
  },
  {
    id: 12,
    name: "Knockout",
    icon: <i className="devicon-knockout-plain-wordmark colored"></i>,
  },
  {
    id: 13,
    name: "Bootstrap",
    icon: <i className="devicon-bootstrap-plain colored"></i>,
  },
  {
    id: 14,
    name: "MaterialUI",
    icon: <i className="devicon-materialui-plain colored"></i>,
  },
  {
    id: 15,
    name: "AWS",
    icon: <i className="devicon-amazonwebservices-original colored"></i>,
  },
  {
    id: 16,
    name: "GitHub",
    icon: <i className="devicon-github-original colored"></i>,
  },
  {
    id: 17,
    name: "Micrososft SQL Server",
    icon: <i className="devicon-microsoftsqlserver-plain colored"></i>,
  },
  {
    id: 18,
    name: ".NET Core",
    icon: <i className="devicon-dotnetcore-plain colored"></i>,
  },
  {
    id: 19,
    name: "VSCode",
    icon: <i className="devicon-vscode-plain colored"></i>,
  },
  {
    id: 20,
    name: "IntelliJ",
    icon: <i className="devicon-intellij-plain colored"></i>,
  },
  {
    id: 21,
    name: "Visual Studio",
    icon: <i className="devicon-visualstudio-plain colored"></i>,
  },
  {
    id: 22,
    name: "Npm",
    icon: <i className="devicon-npm-original-wordmark colored"></i>,
  },
  {
    id: 23,
    name: "Yarn",
    icon: <i className="devicon-yarn-plain colored"></i>,
  },
];
