import { useRef } from "react";
import { PUBLIC_KEY, SERVICE_ID, TEMPLATE_ID } from "../apikeys";
import emailjs from "@emailjs/browser";
import {
  FormLabel,
  Input,
  Textarea,
  Button,
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";

const Contact = () => {
  const form = useRef();
  const toast = useToast();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        toast({
          title: "Sent Successfully!",
          status: "success",
          position: "top-right",
          isClosable: true,
        });
        e.target.reset();
      },
      (error) => {
        console.log(error.text);
        toast({
          title: "Something went wrong",
          status: "error",
          position: "top-right",
          isClosable: true,
        });
      }
    );
  };

  return (
    <Box id="contact" bg="#647C90" color="#DEE6E0" paddingBottom={12}>
      <Box w={[300, 600, 800]} pos="relative" marginLeft={4}>
        <Text fontSize="4xl" direction="column">
          Let's get in touch!
        </Text>
        <Text color="#C0C0C0" paddingLeft={8} flexWrap="inherit">
          If you're curious to know more about programming, technology,
          veterans, or just want to chat. Send me a message!
        </Text>
      </Box>
      <Box
        width="fit-content"
        mx="auto"
        p={6}
        bg="#C0C0C0"
        rounded="lg"
        pos="relative"
        marginTop={6}
      >
        <Flex direction="column" align="center" color="black">
          <Text fontSize="3xl">Contact Me</Text>
          <form ref={form} onSubmit={sendEmail}>
            <FormLabel>Name</FormLabel>
            <Input
              placeholder="Your Name"
              bg="white"
              name="user_name"
              isRequired
            />
            <FormLabel marginTop={4}>Email</FormLabel>
            <Input
              type="email"
              placeholder="Your Email"
              bg="white"
              name="user_email"
              isRequired
            />
            <FormLabel marginTop={4}>Message</FormLabel>
            <Textarea
              placeholder="Enter your message here"
              bg="white"
              name="message"
            />
            <Button
              type="submit"
              mt={4}
              bg="#4E4F50"
              _hover={{ bg: "#41729F" }}
              color="#DEE6E0"
            >
              Submit
            </Button>
          </form>
        </Flex>
      </Box>
    </Box>
  );
};

export default Contact;
